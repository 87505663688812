<template>
  <div class='live-mapper'>
    <div>
      <el-row :gutter='10' style='margin-bottom: 10px;text-align: left'>
        <el-col :span='1.5'>
          <LiveDeptSelect style="width: 200px;display: inline-block" ref='ecp_dept'
                          :props="{ expandTrigger: 'hover' ,value: 'dpt_id', label: 'name', children: 'children',checkStrictly: true}"
                          @handleSelect='selectDept'/>
        </el-col>
        <el-col :span="1.5">
          <el-select v-model="searchCond.live_type" placeholder="请选择直播类型" clearable @change="getList">
            <el-option
                v-for="item in liveTypeOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span='1.5' v-hasAuthority="'live_group_list'">
          <el-button type='primary' @click='getList' icon='el-icon-refresh'>搜索</el-button>
        </el-col>
        <el-col :span='1.5' v-hasAuthority="'live_group_create'">
          <el-button type='primary' @click='handleAdd' icon='el-icon-plus'>添加</el-button>
        </el-col>
        <el-col :span="1.5" v-hasAuthority="'live_group_list'">
          <el-button type='primary' v-hasAuthority="'live_group_list'" icon="el-icon-download"
                     v-loading="downloadLoading" @click='exportExcel'>导出
          </el-button>
        </el-col>
      </el-row>
      <div class="default-table" v-hasAuthority="'live_group_list'">
        <el-row>
          <el-col>
            <el-table border :data='dataList' v-loading="downloadLoading" style="width: 1056px">
              <el-table-column type='index' label='序号' align='center' width='55'></el-table-column>
              <el-table-column label='项目组' prop='group_name' width='260' align='center' sortable='custom'>
                <template slot-scope='{row}'>
                  <span>{{ row.dept_name || '-' }}</span>
                </template>
              </el-table-column>
              <el-table-column label='类型' prop='_live_type' width='160' align='center'></el-table-column>
              <el-table-column label='创建时间' width="200" show-overflow-tooltip prop='created_at'
                               align='center'></el-table-column>
              <el-table-column label='修改时间' width="200" show-overflow-tooltip prop='updated_at'
                               align='center'></el-table-column>
              <el-table-column label='操作' align='center' width='180'>
                <template slot-scope='{row,$index}'>
                  <el-button type='text' @click='handleEdit(row)' icon='el-icon-edit'
                             v-hasAuthority="'live_group_edit'">
                    修改
                  </el-button>
                  <el-button type='text' @click='handleRemove(row,$index)' icon='el-icon-remove'
                             v-hasAuthority="'live_group_delete'">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row>
          <el-col style="width: 1056px">
            <pagination :total.sync='total' :limit.sync='pageData.page_size' :page.sync='pageData.current_page'
                        @pagination='getList'/>
          </el-col>
        </el-row>
      </div>
    </div>
    <!--    添加  -->
    <SettingCreateEdit v-if="dialogVisible" :visible.sync='dialogVisible' :id='currentId' @saved='saveSetting'/>

  </div>
</template>

<script>
import LiveDeptSelect from '@/pages/live_data/LiveDeptSelect.vue'
import SettingCreateEdit from '@/pages/live_data/SettingCreateEdit'
import * as XLSX from 'xlsx-js-style'

export default {
  name: 'liveMapperList',
  components: { LiveDeptSelect, SettingCreateEdit },
  data() {
    return {
      currentId: null,
      dialogVisible: false,
      dataList: [],
      pageData: { page_size: 15, current_page: 1 },
      total: 0,
      // 非多个禁用
      searchCond: {},
      liveTypeOptions: [
        {value: 1,name: '达播' },
        { value: 2,  name: '日播' }
      ],
      downDataList: [],
      downloadLoading: false,
    }
  },
  methods: {
    saveSetting() {
      this.searchCond = {}
      this.getList()
    },
    // 下载表格
    exportExcel: function() {
      this.downloadLoading = true
      this.getAllData()
    },
    async getAllData() {
      let pageData = {
        page_size: this.pageData.page_size,
        current_page: 1,
        total: this.total
      }
      await this.getDownList(pageData)
      if (this.downDataList.length === this.total) {
        const workbook = XLSX.utils.book_new()
        let header = ['序号', '项目组', '类型', '创建时间', '修改时间']
        if (this.downDataList.length === this.total) {
          let data = this.downDataList.map((item, i) => {
            return {
              '序号': i + 1,
              '项目组': `${item.dept_name}${item.group_name ? '/' + item.group_name : ''}`,
              '类型': item._live_type,
              '创建时间': item.created_at,
              '修改时间': item.updated_at
            }
          })
          let ws = XLSX.utils.json_to_sheet(data, { header: header })
          XLSX.utils.book_append_sheet(workbook, ws, '直播组设置表')
          XLSX.writeFile(workbook, `直播组设置表.xlsx`)
          this.downloadLoading = false
        }
      }

    },
    async getDownList(pageData) {
      let searchCond = { ...this.searchCond }
      searchCond?.groupIds?.length === 0 ? delete searchCond.groupIds : ''
      let { data, pages } = await this.$api.getLiveGroupList(Object.assign(searchCond, pageData))
      this.downDataList = [...this.downDataList, ...data]
      pageData.total = pages.total
      pageData.page_size = pages.page_size
      pageData.current_page = pages.current_page

      if (this.downDataList.length < pageData.total) {
        pageData.current_page++
        await this.getDownList(pageData)
      } else {
        return false
      }
    },
    /* 设置工作簿样式 */
    selectDept(val) {
      if (val && val.length > 0) {
        this.searchCond.dept_id = val[val.length - 1].dpt_id
      } else {
        this.searchCond.dept_id = ''
      }
      this.getList()
    },

    handleEdit(row) {
      this.currentId = row.id
      this.dialogVisible = true
    },
    handleAdd() {
      this.currentId = null
      this.dialogVisible = true
    },
    handleSearchCondition() {
      let cond = {}
      if (this.searchCond?.dept_id && this.searchCond?.dept_id !== '') {
        cond.dept_id = this.searchCond.dept_id
      }
      if (this.searchCond?.live_type !== '') {
        cond.live_type = this.searchCond.live_type
      }

      return cond
    },
    //加载列表
    async getList() {
      let searchData = this.handleSearchCondition()
      let { list, pages } = await this.$api.getLiveGroupList(Object.assign(searchData, this.pageData))
      this.dataList = list
      this.total = pages.total
      this.pageData.page_size = pages.page_size
      this.pageData.current_page = pages.current_page
    },

    async delRow(id) {
      let info = await this.$api.delLiveGroup(id)
      if (info) {
        this.msgSuccess('删除成功')
      }
    },
    handleRemove(row, $index) {
      this.$confirm(`确认删除?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.dataList.splice($index, 1)
        await this.delRow(row.id)
        await this.getList()
      })
    },
  },
  created() {
    this.getList()
  }
}
</script>

<style scoped>
.live-mapper {
  padding: 10px;
  margin: auto;
  /*border: #00feff 1px dashed;*/
  width: 100%;
  text-align: center;
}
</style>
