<template>
  <div style='text-align: left'>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' width="500px" modal-append-to-body
               :title='dialogTitle'>
      <el-row :gutter='10'>
        <el-col :span='24'>
          <div class='card'>
            <div class='card-box'>
              <el-form :model='formData' :inline='false' label-position='left' label-width="140px">
                <el-form-item label='项目部&组：' required>
                  <LiveDeptSelect  style="width: 200px;display: inline-block" ref='ecp_dept'
                                  :groupIds="deptIds"
                                  :props="{ expandTrigger: 'hover' ,value: 'dpt_id', label: 'name', children: 'children',checkStrictly: true}"
                                  @handleSelect='selectDept'/>
                </el-form-item>
                <el-form-item label="类型：" required>
                  <el-select required v-model="formData.live_type" placeholder="请选择" clearable>
                    <el-option
                        v-for="item in departmentType"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-col>
      </el-row>
      <div slot='footer' style='text-align: center'>
        <el-button type='primary' size='medium' @click='onSubmit'>保存</el-button>
        <el-button size='medium' @click='close'>取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import LiveDeptSelect from '@/pages/live_data/LiveDeptSelect.vue'


export default {
  name: 'SettingLiveCreateEdit',
  components: { LiveDeptSelect },
  computed: {
    dialogTitle() {
      return `${this.id ? '修改（ID：' + this.id + '）' : '添加'} 部门类型`
    },
    isAdd() {
      return !this.id
    }
  },
  props: {
    id: {
      type: [String, Number],
      default() {
        return null
      }
    }
  },
  data() {
    return {
      loadingCommit: false,
      formData: {
        dept_id: null,
        dept_name: null,
        group_id: '',
        group_name: '',
      },
      dept_group: {
        dpt_id: '',
        name: '',
        members: []
      },
      dept_manage: {
        dpt_id: '',
        name: '',
        members: []
      },
      departmentType: [
        { value: 1, name: '达播' },
        { value: 2, name: '主播' }
      ],
      deptIds: []

    }
  },
  mounted() {
    this.onOpen()

  },
  methods: {
    editLeader(type) {
      this[type] = !this[type]
    },
    onOpen() {
      this.loadingCommit = false
      if (this.isAdd) {
        this.formData = {
          dept_id: null,
          dept_name: null,
          dept_type: null
        }
        this.dept_group = {
          dpt_id: '',
          name: '',
          members: []
        }
        this.dept_manage = {
          dpt_id: '',
          name: '',
          members: []
        }
      } else {
        this.getAInfo()
      }
    },
    onClose() {

    },
    close() {
      this.formData = {}
      this.$emit('update:visible', false)
    },
    async getAInfo() {
      //获取编辑对象的信息
      let { info } = await this.$api.getLiveGroup(this.id)
      let ids = info?.dept_arr.map((item) => {
        return item.dept_id
      })
      this.deptIds = ids
      this.formData = { ...info }
    },


    async selectDept(arr) {
      if (arr && arr.length != 0) {
        this.formData.dept_id = arr[arr.length - 1].dpt_id
        this.formData.dept_name = arr[arr.length - 1].name
      }
    },
    //获取部门信息及其成员
    async getGroupMembers(dept) {

      let members = []
      let searchCondition = { page_size: 50, dpt_id: dept.dpt_id, current_page: 1 }
      let { list, pages } = await this.$api.getDptUsers(searchCondition)
      members = [...members, ...list]
      searchCondition.page_size = pages.page_size
      searchCondition.current_page = pages.current_page
      for (let i = pages.offset + list.length; i < pages.total; i += pages.page_size) {
        searchCondition.current_page += 1
        let { list } = await this.$api.getDptUsers(searchCondition)
        members = [...members, ...list]
      }
      return { dpt_id: dept.dpt_id, name: dept.name, members: members }
    },

    async onSubmit() {
      console.log(this.formData)
      if (!this.formData.dept_id) {
        this.msgWarn('项目组必选')
        return false
      }else if(!this.formData.live_type){
        this.msgWarn('项目组类型')
        return false
      }
      await this.storeData(this.formData)
    },
    async storeData(val) {
      let id = await this.$api.saveLiveGroup(val)
      if (id) {
        this.msgSuccess('保存成功')
        // 模拟
        this.$emit('saved', this.formData)
        this.close()
      }
    }

  },
}
</script>

<style scoped>
.card {
  line-height: 400px;
  width: 100%;
}

.card-box {
  padding: 10px 20px;
  line-height: 400px;
  height: 150px;
  border: rgba(44, 39, 39, 0.5) 1px dashed;
  margin-bottom: 20px;
  font-weight: 400;
}

.icon {
  cursor: pointer;
  color: #ff3176;
  display: inline-block
}
</style>
